
.form-agreement-field{
    border:1px solid black !important;
  }
.field_Label{
  color: black;
}
.agreementTable th{
  text-align: center;
  vertical-align: middle !important;
}
.agreementTable td{
  text-align: center;
  padding: 5px 6px !important;
  vertical-align: middle !important;
}

.agreementContent {
  page-break-inside: avoid;
}