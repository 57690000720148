.invoice-head td {
    padding: 0 8px;
  }
  .container {
    padding-top:30px;
  }
  .invoice-body{
    background-color:transparent;
  }
  .container {
    padding-left: 180px;
    padding-right: 180px;
    padding-top: 50px;
    padding-bottom: 50px;
   
  }

  .div{
    padding: 10px;
    border: 1px solid grey;
  }
  